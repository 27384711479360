import { Box, Typography } from '@mui/material';
import { differenceInBusinessDays, isFuture } from 'date-fns';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AllocationStatus, formatDateTimeString, IAllocation } from '../../../shared';

interface Props {
    allocation: IAllocation;
}

export const AllocationTrackDeadlines: FC<Props> = ({ allocation }) => {
    const { t } = useTranslation();
    const deadlineInvite = window.carity.environment.deadlineResponseInvite;
    const deadlineVisit = window.carity.environment.deadlineResponseVisit;
    const invited = allocation.status === AllocationStatus.INVITED;
    const planned =
        allocation.status === AllocationStatus.APPOINTMENT_PLANNED &&
        allocation.appointment?.date &&
        isFuture(new Date(allocation.appointment.date));
    const visited = allocation.status === AllocationStatus.APPOINTMENT_PLANNED && !planned;

    if (invited || visited) {
        const difference = invited
            ? allocation.invite?.createdDate
                ? differenceInBusinessDays(new Date(), new Date(allocation.invite.createdDate))
                : undefined
            : allocation.appointment?.date
              ? differenceInBusinessDays(new Date(), new Date(allocation.appointment.date))
              : undefined;

        const days = difference && (invited ? deadlineInvite - difference : deadlineVisit - difference);

        return (
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
                <Typography variant="body2">
                    {invited ? t('actionMakeAppointment') : t('actionGiveFeedback')}
                </Typography>

                {days !== undefined && (
                    <Typography variant="caption" sx={{ color: days + 1 > 0 ? 'green' : 'orange', mb: 0 }}>
                        {days + 1 > 0
                            ? t('actionDaysRemaining', {
                                  count: difference == 0 ? (invited ? deadlineInvite : deadlineVisit) : days,
                              })
                            : t('actionDaysLate', { count: parseInt(days.toString().replace('-', '')) })}
                    </Typography>
                )}
            </Box>
        );
    } else if (planned) {
        return (
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%">
                <Typography variant="body2">{t('actionAppointmentPlanned')}</Typography>
                <Typography variant="caption">
                    {formatDateTimeString(allocation.appointment?.date.toString())}
                </Typography>
            </Box>
        );
    }
};
